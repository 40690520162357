.board-wrapper {
  position: relative;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  background: rgb(214, 183, 9);
}
.board {
  position: absolute;
}

.boardLine {
  background: #222;
  position:absolute;
}

.boardLine.vertical {
  width: 1px;
}

.boardLine.horizontal {
  height: 1px;
}