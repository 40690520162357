.stone {
  border-radius: 50%;
  position: absolute;
  z-index: 9999;
}

.stone.black {
  background: #222;
}

.stone.white {
  background: #eee;
}

.stone.empty {
  border: none;
}