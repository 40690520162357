body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  padding: 20px 0 20px 20px;
}

.tools div {
  display: inline-block;
}

.tools .tool-stone {
  width: 18px;
  height: 18px;
  border-radius:50%;
  border: 2px solid #ccc;
}

.tools .tool-wrapper {
  margin-right: 20px;
  padding-bottom: 4px;
}
.tools div.active {
  border-bottom: 3px solid green;
}
.tools .tool-stone.black {
  background: #000;
}

.tools .tool-stone.white {
  background: #fff;
}

.tool-eraser {
  vertical-align: top;
}
.board-wrapper {
  position: relative;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  background: rgb(214, 183, 9);
}
.board {
  position: absolute;
}

.boardLine {
  background: #222;
  position:absolute;
}

.boardLine.vertical {
  width: 1px;
}

.boardLine.horizontal {
  height: 1px;
}
.stone {
  border-radius: 50%;
  position: absolute;
  z-index: 9999;
}

.stone.black {
  background: #222;
}

.stone.white {
  background: #eee;
}

.stone.empty {
  border: none;
}
