.App {
  padding: 20px 0 20px 20px;
}

.tools div {
  display: inline-block;
}

.tools .tool-stone {
  width: 18px;
  height: 18px;
  border-radius:50%;
  border: 2px solid #ccc;
}

.tools .tool-wrapper {
  margin-right: 20px;
  padding-bottom: 4px;
}
.tools div.active {
  border-bottom: 3px solid green;
}
.tools .tool-stone.black {
  background: #000;
}

.tools .tool-stone.white {
  background: #fff;
}

.tool-eraser {
  vertical-align: top;
}